import { Grid, Typography } from "@mui/material"
import { tss } from "tss-react"
import resumeData from "../../utils/resumeData"
import SectionTitle from "../shared/SectionTitle"
import TvYoutube from "../shared/TvYoutube"

const useStyles = tss.create({
    projectItem: {
        backgroundColor: '#EEE',
        borderRadius: '5px',
        padding: '20px',
        margin: '10px'
    },
    projectDescription: {
        color: '#707070 !important',
        padding: '10px'
    },
    projectMedia: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px'
    },
    iframe: {
        width: '100%',
        height: '600px', // Adjust height as needed
        border: 'none'
    }
})

function Portfolio() {
    const { classes } = useStyles()

    return (
        <Grid padding={'24px'}>
            <SectionTitle text='Portfolio' />
            <Grid container xs={12}>
                {resumeData.projects.map((project) => {
                    const [linkType, linkValue] = project.link.includes("-") ? project.link.split("-", 2) : ["youtube", project.link]

                    return (
                        <Grid item xs={12} className={classes.projectItem} key={project.name}>
                            <Grid item>
                                <Typography>{project.icon} {project.name}</Typography>
                                <Typography variant='caption' className={classes.projectDescription}>{project.description}</Typography>
                            </Grid>
                            <Grid item className={classes.projectMedia}>
                                {linkType === 'iframe' ? (
                                    <iframe 
                                        src={linkValue} 
                                        className={classes.iframe}
                                        allowFullScreen
                                    />
                                ) : (
                                    <TvYoutube videoId={linkValue} />
                                )}
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default Portfolio
