import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import ComputerIcon from '@mui/icons-material/Computer'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import SchoolIcon from '@mui/icons-material/School'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import InsightsIcon from '@mui/icons-material/Insights'
import HtmlIcon from '@mui/icons-material/Html'
import CssIcon from '@mui/icons-material/Css'
import PhpIcon from '@mui/icons-material/Php'
import JavascriptIcon from '@mui/icons-material/Javascript'
import CloudIcon from '@mui/icons-material/Cloud'
import TerminalIcon from '@mui/icons-material/Terminal'
import StorageIcon from '@mui/icons-material/Storage'
import DiamondIcon from '@mui/icons-material/Diamond'
import NightlifeIcon from '@mui/icons-material/Nightlife'
import SportsFootballIcon from '@mui/icons-material/SportsFootball'
import { ReactElement } from 'react'

export type ResumeData = {
    name: string
    position: string
    profile: TimelineData[]
    socials: SocialsData[]
    summary: string
    education: TimelineData[]
    softSkills: SoftSkills[]
    devSkills: DevSkills[]
    workHistory: TimelineData[]
    projects: ProjectData[]
}

export type TimelineData = {
    name: string
    startDate?: number
    endDate?: number
    description: string
}

export type SocialsData = {
    text: string
    link: string
    icon: ReactElement
}

export type SoftSkills = {
    name: string,
    description: string,
    icon: ReactElement
}

export type DevSkills = {
    name: string,
    years: number,
    icon: ReactElement
}

export type ProjectData = {
    name: string,
    description: string,
    icon: ReactElement,
    link: string
}

const resumeData: ResumeData = {
    name: `Thomas Viles Jr`,
    position: `Software Engineer`,
    profile: [
        {
            name: `birthday`,
            description: `July 20th 1990`,
        },
        {
            name: `email`,
            description: `tcv720@gmail.com`,
        },
        {
            name: `location`,
            description: `Brownsburg, IN`,
        },
        {
            name: `phone`,
            description: `317-696-0339`,
        }
    ],
    socials: [
        {
            text: `Facebook`,
            link: `https://www.facebook.com/thom.viles`,
            icon: <FacebookIcon />
        },
        {
            text: `LinkedIn`,
            link: `https://www.linkedin.com/in/thomasvilesjr/`,
            icon: <LinkedInIcon />
        },
        {
            text: `Github`,
            link: `https://github.com/Tcviles`,
            icon: <GitHubIcon />
        },
    ],
    softSkills: [
        {
            name: `Web Development`,
            description: `I have been a full stack developer since November 2017`,
            icon: <ComputerIcon />
        },
        {
            name: `Mentorship`,
            description: `Always willing to take on a student to help them become the best developer they can be.`,
            icon: <LocalLibraryIcon />
        },
        {
            name: `Professional Development`,
            description: `Learning the latest technologies to be ahead of the curve.`,
            icon: <SchoolIcon />
        },
        {
            name: `Accurate Delivery`,
            description: `Veteran mindset to get things done right the first time, as effeciently as possible`,
            icon: <FactCheckIcon />
        },
    ],
    devSkills: [
        {
            name: `Agile/Scrum`,
            years: 6,
            icon: <InsightsIcon />
        },
        {
            name: `API Gateway`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `AWS`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Cypress`,
            years: 2,
            icon: <FactCheckIcon />
        },
        {
            name: `CSS`,
            years: 6,
            icon: <CssIcon />
        },
        {
            name: `Cloudfront`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Cloudwatch`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Docker`,
            years: 6,
            icon: <TerminalIcon />
        },
        {
            name: `DynamoDB`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `ECS`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `EC2`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Elasticache`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Git`,
            years: 6,
            icon: <GitHubIcon />
        },
        {
            name: `HTML`,
            years: 6,
            icon: <HtmlIcon />
        },
        {
            name: `IAM`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Jest`,
            years: 2,
            icon: <FactCheckIcon />
        },
        {
            name: `Javascript`,
            years: 6,
            icon: <JavascriptIcon />
        },
        {
            name: `Kubernetes`,
            years: 6,
            icon: <TerminalIcon />
        },
        {
            name: `Lambda`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `NoSQL`,
            years: 3,
            icon: <StorageIcon />
        },
        {
            name: `PHP`,
            years: 2,
            icon: <PhpIcon />
        },
        {
            name: `React.js`,
            years: 6,
            icon: <JavascriptIcon />
        },
        {
            name: `RDS`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Route53`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `S3`,
            years: 2,
            icon: <StorageIcon />
        },
        {
            name: `SQL`,
            years: 6,
            icon: <StorageIcon />
        },
        {
            name: `SNS`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `SQS`,
            years: 2,
            icon: <CloudIcon />
        },
        {
            name: `Typescript`,
            years: 2,
            icon: <JavascriptIcon />
        }
    ],
    summary: 
    `Experienced full-stack developer with a strong focus on object-oriented back-end development. 
    Proven track record of timely collaboration in team environments. 
    A military veteran and Flatiron coding school alumni, I bring a dedicated work ethic and quick learning ability. 
    Seeking a Software Engineer role to contribute to backend and frontend development. 
    Proficient in Node, React.js, and SQL as well as NoSQL databases, with expertise in backend services. 
    Known for effective communication, technical ownership, and a results-driven approach. 
    Feel free to view my recommendations below.`,
    education: [
        {
            name: `AWS Certified Developer Associate`,
            startDate: 2023,
            endDate: 2026,
            description: 
            `Validates proficiency in developing and deploying applications on the Amazon Web Services platform. 
            Demonstrates expertise in designing, building, and maintaining cloud-based solutions using AWS services, 
            enhancing credibility in software development and cloud computing.`
        },
        {
            name: `AWS Solutions Architect Associate`,
            startDate: 2022,
            endDate: 2025,
            description: 
            `Validates skills in designing and deploying scalable, secure, and fault-tolerant systems on the Amazon Web Services platform. 
            Demonstrates proficiency in architecting cloud-based solutions that meet business requirements, 
            enhancing expertise in cloud architecture and infrastructure design.`
        },
        {
            name: `Flatiron coding school`,
            startDate: 2017,
            endDate: 2018,
            description: 
            `A coding bootcamp that offers immersive courses in software engineering, teaching HTML, CSS, Javascript, React, Redux, and Ruby on Rails. 
            It provides hands-on training and mentorship to equip students with the skills and experience needed for careers in technology. 
            The curriculum focuses on practical, real-world projects and industry-relevant skills to prepare graduates for success in the tech industry.`
        },
        {
            name: `Indiana University - Purdue University Indianapolis`,
            startDate: 2010,
            endDate: 2014,
            description: 
            `Where I pursued coursework in Biology. While attending, I served as a mentor for Anatomy and Physiology,
            providing guidance and support to fellow students. The biology program at IUPUI offers a comprehensive curriculum with 
            hands-on laboratory experience and research opportunities, preparing students for careers in healthcare, research, education, 
            and beyond.`
        },
    ],
    workHistory: [
        {
            name: `Black Widow Imaging`,
            startDate: 2024,
            description: 
            `Developed and maintained front-end applications using React, React Native, and Material UI, 
            delivering modern, responsive user interfaces. Built and optimized back-end services with Django and 
            Python, ensuring scalable and efficient data processing. Spearheaded the implementation of AWS Cognito 
            for authentication and AWS CodePipeline for CI/CD automation, enhancing security and streamlining deployments. 
            Established as the SME for AWS services, providing expertise in cloud architecture and infrastructure. 
            Led the adoption of automated testing using Jest and Cypress, improving software reliability and deployment confidence. 
            Leveraged AI tools such as ChatGPT to enhance research and design efficiency, accelerating development cycles and 
            optimizing problem-solving strategies. Contributed to full-stack development, cloud engineering, and test automation, 
            driving innovation and efficiency across projects.`
        },
        {
            name: `Chronotrack`,
            startDate: 2022,
            endDate: 2024,
            description: 
            `Spearheaded resolution of common timing software bugs and introduced new features within the Chronotrack ecosystem. 
            Played a pivotal role in the migration from legacy PHP 1.0 web applications to a modern React 2.0 framework, 
            resulting in notable improvements in user experience and system performance. Established as a SME in developing 
            Lambda- based, event - driven microservices using Node.js, contributing to the scalability and robustness of the platform. 
            Demonstrated proficiency in a diverse set of technologies including AWS, Cypress, Docker, Jest, Kubernetes, PHP, React, 
            RTK Query, and Typescript.`
        },
        {
            name: `Finvi / Ontario Systems`,
            startDate: 2018,
            endDate: 2022,
            description: 
            `Contributed to the development of multiple features, collaborating with both new cache classes 
            and legacy financial code at Finvi. Key contributor to the transition to SaaS, specializing 
            in API feature development. Served as SME for our ConnectAPI V2. Developed proficiency in various 
            technologies and methodologies including Git, Vagrant, Docker, and Agile/Scrum practices using JIRA. 
            Actively participated in the mentorship program, guiding and supporting interns to facilitate their 
            transition into professional coding practices and foster their growth as developers.`
        },
        {
            name: `Uber`,
            startDate: 2018,
            endDate: 2023,
            description: 
            `Demonstrated strong communication and customer service skills through experience as an Uber driver, 
            effectively engaging with diverse passengers to provide positive and satisfactory experiences. Developed 
            robust problem-solving abilities and adaptability by navigating various challenges as an Uber driver, 
            honing the capacity to think on my feet and find creative solutions to unforeseen circumstances.`
        },
        {
            name: `US Army`,
            startDate: 2014,
            endDate: 2016,
            description: 
            `Instructed new soldiers in essential military skills, promoting discipline and efficiency 
            through rigorous training in marksmanship and precise task execution. Emphasized the significance 
            of swift and accurate implementation, fostering leadership abilities and reinforcing principles 
            vital for agile software development practices.`
        },
    ],
    projects: [
        {
            name: `Ruby CLI Project`,
            description: `This was my first project for my coding bootcamp. Recorded and submitted in Jan 2018. This project just used Ruby before learning the Rails framework.`,
            link: `DH6cWyXXJIo`,
            icon: <DiamondIcon />
        },
        {
            name: `Sinatra Project`,
            description: `My first project using MVC development was a makeshift dentist application. This was my entry into developing authentication, CRUD development, and using ERB for my front end development. During this video I also show some of my troubleshooting skills that I developed early on in my career. This project was submitted February 2018. (I was sick while recording this video so I apologize for the sniffles.)`,
            link: `zo3IpJk9qLM`,
            icon: <NightlifeIcon />
        },
        {
            name: `Fantasy Football Rails Project`,
            description: `Fantasy football has always been one of my favorite hobbies. For my Ruby on Rails project, I decided to try to make a miniature Fantasy Football application. Shortly after I made this project, I got my first opportunity as a professional Software Engineer. The key skills I learned during this project were using RESTful API's and further developing the skills I learned in my Sinatra section.This project was submitted March 2018.`,
            link: `dSMkriLCt8Y`,
            icon: <SportsFootballIcon />
        },
        {
            name: 'Concrete Calculator',
            description: 'An interactive web-based concrete volume calculator built with React, AWS, and Material UI.',
            link: 'iframe-https://concretecalc.tviles.com',
            icon: <CloudIcon />
        }
        
    ]
}

export default resumeData
